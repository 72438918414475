import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'view-image-dialog',
  templateUrl: './view-image-dialog.component.html',
  styleUrls: ['./view-image-dialog.component.scss']
})

export class ViewImageDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ViewImageDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {

    console.log("dialog data = ", data);
  }

  ngOnInit(): void { }

}
