import * as moment from 'moment';

export class Location {
  public lat: number = null;
  public lng: number = null;

  constructor(id?: string | Object) {
    if (typeof id == 'object') {
      this.import(id);
    } 
  }

  exists() {
    return this.lng != null && this.lat != null;
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

 
}
