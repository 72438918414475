import { Location } from './location';
import { Question } from './question';
import { User } from './User';

export class Answer {
  public id: number = null
  public type: string = null;
  public result: any = null;
  public position: Location = null;
  public created_at: string = null;
  public question: Question = null;
  public user: User = null;

  constructor(id?: string | Object) {
    if (typeof id == 'object') {
      this.import(id);
    }
  }

  exists() {
    return this.id != null;
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          case 'location':
            this.position = new Location(data[key]);
            break;
            case 'question':
              this.question = new Question(data[key]);
              break;
            case 'user':
              if (data[key]) {
                this.user = new User(data[key]);
              }
              break;
          default:
            this[key] = data[key];
        }
      }
    }
  }
}
