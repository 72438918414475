
import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { CustomDateAdapter } from 'src/app/adapters/customdate.adapter';

export interface FieldsColumns {
  key: any;
  fieldPlaceholder?: string;
  endpoint?: any;
  width?: string,
  inputType?: string;
  minValue?: any;
  maxValue?: any;
  editable?: boolean;
  required?: boolean;
  unique?: boolean;
  hidden?: boolean,
  validate?: (v: any, row: any, rows: any) => any,
  withInputChangeValue?: boolean;
  onInputChangeValue?: (v: any, row: any, columns: FieldsColumns, rowDef: any, rowIndex) => any,
  formatter?: (v: any, mainObject: any) => any;
  suffix?: any;
  // enterNewValueLabel?: string;
  minRows?: number;
  cssClass?: (mainObject: any) => string;
};

const defaultFieldsColumns: FieldsColumns = {
  key: null,
  fieldPlaceholder: null,
  endpoint: null,
  width: '100%',
  inputType: 'text',
  minValue: null,
  maxValue: null,
  editable: true,
  required: false,
  unique: false,
  hidden: false,
  validate: (v, row = null, rows = null) => { return []; },
  withInputChangeValue: false,
  onInputChangeValue: (v, row = null, columns = null, rowDef = null, rowIndex=null) => { return; },
  formatter: (v) => { return v; },
  suffix: null,
  // enterNewValueLabel: null,
  minRows: 1,
  cssClass: (mainObject) => { return '' },
};

@Component({
  selector: 'editable-grid-field',
  templateUrl: './editable-grid-field.component.html',
  styleUrls: ['./editable-grid-field.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: EditableGridField },
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EditableGridField
  implements
  MatFormFieldControl<any>,
  ControlValueAccessor,
  OnInit,
  DoCheck {
  ready: boolean = false;

  public dataSource: any[] = [];
  public controls = [];
  public displaiedFields: string[] = [];
  public selectedRows = [];
  public invalidFields = [];

  stateChanges = new Subject<void>();
  id: string;
  focused: boolean;
  shouldLabelFloat: boolean;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  public cellClasses = {};

  @Input()
  readonly: boolean = false;

  @Input() set fieldsColumnsDef(columns) {
    const cols = [];
    columns.forEach(c => {
      const d = Object.assign({}, defaultFieldsColumns);
      const o = Object.assign(d, c);
      cols.push(o);
    });

    // actions col
    if (!this.readonly && this.canRemove) {
      cols.push(
        {
          key: 'actions',
          inputType: 'actions',
        }
      );
    }

    this._fieldsColumns = cols;
    //console.log('fieldsColumnsDef', cols)
  }
  _fieldsColumns: FieldsColumns[] = [];

  @Input() set rowDef(rowDef) {
    this._rowDef = rowDef;
  }
  get rowDef() {
    return this._rowDef;
  }
  private _rowDef = [];

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get value(): any | null {
    return this._value;
  }
  set value(val: any | null) {
    
    this.initValue(val);
    this.stateChanges.next();
    this.onChange(val);
    this.onTouch();
  }
  private _value: any = null;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  get empty() {
    return !this._value || this._value.length == 0;
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {

  }
  private _disabled = false;

  @Input() addLabel = null;
  @Input() canAdd = true;
  @Input() endpoint = null;
  @Input() showLabelButton = null;
  @Input() canRemove = true;


  @Output() valid: EventEmitter<any> = new EventEmitter();
  public isValid = true;


  @ViewChild(MatTable) table: MatTable<any>;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    // console.log(event)
    let excluded = _.find(event.path,function(o) { 
      if(o.className) {
        if(o.className.indexOf('mat-datepicker-content') !== -1 || o.className.indexOf('opened-line') !== -1)
        return o;
      }
    })
    if(excluded) {
    } else {
      this.selectedRows = [];
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private translate: TranslateService,
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  writeValue(value: any): void {
    this.initValue(value);
  }
  registerOnChange(fn: (value: any[]) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]): void {
    throw new Error('Method not implemented.');
  }
  onContainerClick(event: MouseEvent): void {
    throw new Error('Method not implemented.');
  }

  onChange = (value: any[]) => { };

  onTouch = () => { };

  ngOnInit() {
    this.setTableColumns();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  initValue(value: any): void {
    if (!value) {
      value = [];
    } else {
      value = value;
    }

    this._value = value;
    this.dataSource = this._value;
    this.ready = true;

    this.revalidateEditableGridField();
    let vlidationEmit = {
      control_name: this.ngControl.name,
      valid: this.isValid
    };
    this.valid.emit(vlidationEmit);
  }

  setTableColumns() {
    this.displaiedFields = [];
    this._fieldsColumns.forEach(element => {
      if (!element.hidden) {
        this.displaiedFields.push(element.key)
      }
    });
  }

  addItem(event) {
    event.stopPropagation();
    let newRow = Object.assign({}, this._rowDef);
    this.dataSource.push(newRow);

    let newRowIndex = this.dataSource.length - 1;
    // this.selectedRows.push('row_'+newRowIndex);
    this.selectedRows = ['row_' + newRowIndex];

    this.updateValue(newRowIndex, newRow);

    // update the mat table
    this.table.renderRows();

    // focus the first input from the new row
    setTimeout(() => {
      let rows = this.elRef.nativeElement.querySelectorAll(`.mat-row`);
      let last = rows[rows.length - 1];
      if (last) {
        let input = last.querySelector('input');
        if (input) {
          (<HTMLElement>input).focus();
        }
      }
    })
  }

  deleteItem(row_index, row) {
    this.dataSource.splice(row_index, 1);

    let indexOf = this.invalidFields.indexOf('row_' + row_index);
    if (indexOf > -1) {
      this.invalidFields.splice(indexOf, 1);
    }

    this.updateValue();

    // update the mat table
    this.table.renderRows();
  }

  updateValue(row_index = null, row = null, fieldColumn = null) {
    if (fieldColumn) {
      // set date value(for not sending Moment())
      if (fieldColumn.inputType == 'date') {
        row[fieldColumn.key] = this.setDateValue(row[fieldColumn.key]);
      }
    }
    this.update(row_index, row);
    this.onChange(this._value);
    this.onTouch();
  }

  update(row_index = null, row = null) {
    this.revalidateEditableGridField();

    // revalidate EditableGridFieldComponent
    if (this.invalidFields.length == 0) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
    if (this.isValid) {
      this._value = this.dataSource;
    } else {
      this._value = [];
    }
    let vlidationEmit = {
      control_name: this.ngControl.name,
      valid: this.isValid
    };
    this.valid.emit(vlidationEmit);
  }
  onInput(row_index = null, row = null, fieldColumn = null) {
    if (fieldColumn) {
      if (fieldColumn.withInputChangeValue) {
        let onInputChangeValue = fieldColumn.onInputChangeValue(row[fieldColumn.key], row, this._fieldsColumns, this.rowDef, row_index);
        if(onInputChangeValue) {
          if(onInputChangeValue.reset_columns) {
            this.setTableColumns(); 
          }
        }
        this.update(row_index, row);
        this.onChange(this._value);
        this.onTouch();
      }
    }
  }

  revalidateEditableGridField() {
    this.invalidFields = [];
    let total_errors = 0;
    this.dataSource.forEach((val, key) => {
      let errors = 0;
      for (let v in val) {
        let has_errors = this.hasErrors(v, val[v], val);
        if (has_errors) {
          errors++;
          total_errors++;
        }
      }
      let indexOf = this.invalidFields.indexOf('row_' + key);
      if (errors == 0) {
        if (indexOf > -1) {
          this.invalidFields.splice(indexOf, 1);
        }
      } else {
        if (!(indexOf > -1)) {
          this.invalidFields.push('row_' + key);
        }
      }
    });

    if (total_errors == 0) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  onDbClick(event, row_index, column_index) {
    event.stopPropagation();
    if (!this.readonly) {

      if (!this.selectedRows['row_' + row_index]) {
        this.selectedRows.push('row_' + row_index);
      }

      setTimeout(() => {
        // focus the input
        let input = this.elRef.nativeElement.querySelector(`#${this.ngControl.name}_inputElement_${row_index}_${column_index}`);
        if (input) {
          (<HTMLElement>input).focus();
        } else { // if the field is not editable, focus the first input
          let input = event.srcElement.parentNode.parentNode.parentNode.querySelector('input');
          // console.log("input = ",input)
          if (input) {
            (<HTMLElement>input).focus();
          }
        }
      })
      // console.log(this.selectedRows);
    }
  }

  onBlur(event, row_index) {
  }

  onTab(event, row_index, column_index) {
    // stop closing the current selected row(on outside click)
    event.stopPropagation();

    // go to next row if lat input
    if (this._fieldsColumns.length) {
      let array = this._fieldsColumns.filter(function (obj) {
        return obj.key !== 'actions';
      });
      if (array.length && column_index == (array.length - 1)) {
        this.selectedRows = [];
        if (this.dataSource[row_index + 1]) {
          this.selectedRows.push('row_' + (row_index + 1));
        }
      }
    }
  }

  onEnter(event, row_index, column_index) {
    event.stopPropagation();
    this.selectedRows = [];
  }

  isUnique(field_key, field_value) {
    let unique = true;
    let k = 0;
    this.dataSource.forEach((element, key) => {
      if (element[field_key] != "" && element[field_key] == field_value) {
        k++;
      }
    });
    if (k > 1) {
      unique = false;
    }
    return unique;
  }

  setDateValue(date) {
    if (date && date != '') {
      if (typeof (date) == 'object') {
        return date.format('YYYY-MM-DD');
      } else {
        return date;
      }
    } else {
      return null;
    }
  }

  dateChange(event, row_index = null, row = null, fieldColumn = null) {
    this.updateValue(row_index, row, fieldColumn);
  }

  hasErrors(field_key, field_value, row, o = null) {
    let errors = [];

    if (!o) {
      o = _.find(this._fieldsColumns, { key: field_key });
    }

    if (o) {
      errors = o.validate(field_value, row, null);
    }

    let is_unique = this.isUnique(field_key, field_value);

    if ((errors && errors.length) || (o && o.unique && !is_unique)) {
      return true;
    } else {
      return false;
    }
  }

  reloadField(with_on_input_change = false) {
    // wait set fieldsColumnsDef(columns)
    setTimeout(() => {
      this.setTableColumns();
      this.update();
      this.onChange(this._value);
      this.onTouch();

      if(with_on_input_change) {
        this.dataSource.forEach((ds,index) => {
          for(let field in ds) {
            let o = _.find(this._fieldsColumns,{key: field})
            if(o) {
              this.onInput(index, ds, o)
            }
          }
        }) 
      }
    })
  }
}

