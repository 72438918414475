import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';


/**
 * @title Chips Autocomplete
 */
@Component({
    selector: 'fileupload-field',
    templateUrl: './fileupload-field.component.html',
    styleUrls: ['./fileupload-field.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: FileUploadInput },
    ],

})
export class FileUploadInput
    implements
    MatFormFieldControl<File>,
    ControlValueAccessor,
    OnInit,
    DoCheck {
    id: string;
    stateChanges = new Subject<void>();
    static nextId = 0;
    private _placeholder: string;
    focused = false;
    errorState = false;
    controlType = 'fileupload-field';

    fieldCtrl = new FormControl();
    _value: File = null;

    @Input()
    accept: string = '*';
    
    @Input()
    readonly: boolean = false;
    @Input()
    visible: boolean = true;
    @Input()
    addOnBlur: boolean = true;
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    get value(): File | null {
        return this._value;
    }
    set value(val: File | null) {
        this._value = val;
        this.stateChanges.next();
        this.onChange(val);
        this.onTouch();
    }

    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    get empty() {
        return this._value==null;
    }
    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        this._disabled ? this.fieldCtrl.disable() : this.fieldCtrl.enable();
        this.stateChanges.next();
    }
    private _disabled = false;


    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @HostBinding('attr.aria-describedby') describedBy = '';

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef<HTMLInputElement>;


    constructor(
        @Optional() @Self() public ngControl: NgControl,
        fb: FormBuilder,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>
    ) {

        fm.monitor(elRef.nativeElement, true).subscribe(origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        });

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
    }

    onContainerClick(event: MouseEvent) {
        //this.fieldCtrl.setValue('');

        if (!this.readonly && (event.target as Element).tagName.toLowerCase() != 'input') {
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }

    onChange = (value: File) => { };

    onTouch = () => { };

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(value: File): void {
        this._value = value;
        this.fieldCtrl.patchValue(value ? value.name: null);

    }

    // Allows Angular to register a function to call when the model (rating) changes.
    // Save the function as a property to call later here.
    registerOnChange(fn: (value: File) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean): void {

    }

    ngOnInit() { }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }

    updateValue(file: File) {
        if (file) {
            this.fieldCtrl.setValue(file.name);
        }
        this.writeValue(file);
        this.onChange(this._value);
        this.onTouch();
    }


    selectFile() {
        this.fileUpload.nativeElement.value = null;
        this.fileUpload.nativeElement.click();
        
        this.onTouch();
    }

    ngAfterViewInit() {
        this.fileUpload.nativeElement.onchange = () => {
            let file = null;
            for (let index = 0; index < this.fileUpload.nativeElement.files.length; index++) {
                file = this.fileUpload.nativeElement.files[index];
            }
            this.updateValue(file);
        };
    }

}
