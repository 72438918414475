import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/pages/pages.module').then(mod => mod.PagesModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'customer'], title: 'STATIC.NAV.DASHBOARD' },
    loadChildren: () => import('./modules/admin/dashboard/dashboard.module').then(mod => mod.DashboardModule),
  },
  {
    path: 'missions',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'customer'], title: 'STATIC.NAV.MISSIONS.MISSIONS' },
    loadChildren: () => import('./modules/admin/missions/missions.module').then(mod => mod.MissionsModule),
  },
  {
    path: 'tasks',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'customer'], title: 'STATIC.NAV.TASKS.COMPLETED_TASK' },
    loadChildren: () => import('./modules/admin/completed-tasks/completed-tasks.module').then(mod => mod.CompletedTasksModule),
  },
  {
    path: 'payment',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'customer'], title: 'STATIC.NAV.PAYMENT_REQUEST' },
    loadChildren: () => import('./modules/admin/payment-request/payment-request.module').then(mod => mod.PaymentRequestModule),
  },
  {
    path: 'page',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'customer'], title: 'STATIC.NAV.ABOUT' },
    loadChildren: () => import('./modules/pages/pages.module').then(mod => mod.PagesModule),
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin', 'customer'], title: 'STATIC.NAV.SUPPORT' },
    loadChildren: () => import('./modules/admin/messages/messages.module').then(mod => mod.MessagesModule),
  },
  {
    path: 'clients',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.CLIENTS' },
    loadChildren: () => import('./modules/admin/clients/clients.module').then(mod => mod.ClientsModule),
  },
  {
    path: 'customers',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.USERS' },
    loadChildren: () => import('./modules/admin/customers/customers.module').then(mod => mod.CustomersModule),
  },
  {
    path: 'parameters',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.PARAMETERS' },
    loadChildren: () => import('./modules/admin/parameters/parameters.module').then(mod => mod.ParametersModule),
  },
    {
    path: 'users',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.USERS' },
    loadChildren: () => import('./modules/admin/users/users.module').then(mod => mod.UsersModule),
  },
  {
    path: 'lists',
    canActivate: [AuthGuard],
    data: { roles: ['super-admin'], title: 'STATIC.NAV.LISTS' },
    loadChildren: () => import('./modules/admin/value-lists/value-lists.module').then(mod => mod.ValueListsModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(mod => mod.ErrorsModule),
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

