import { EditUserDialog } from '../components/dialogs/userdialog/user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authentication.service';
import { DataService } from './data.service';
import { AlertDialog } from '../components/dialogs/alertdialog/alert-dialog.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root"
})
export class NavigationService {
  public pageTitle = '';

  protected main_menu_auth = [
    { type: "item", label: "STATIC.NAV.HOME", path: "/dashboard", icon: "home", roles: ['super-admin'] },
    { type: "separator" },
    { type: "item", label: "STATIC.NAV.MISSIONS.MISSIONS", path: "/missions", icon: "task", roles: ['super-admin','customer'] },
    { type: "separator" },
    { type: "item", label: "STATIC.NAV.TASKS.COMPLETED_TASK", path: "/tasks", icon: "fact_check", roles: ['super-admin','customer'] },
    { type: "separator" },
    { type: "item", label: "STATIC.NAV.PAYMENT_REQUEST", path: "/payment", icon: "fact_check", roles: ['super-admin','customer'] },
    { type: "separator" },
    { type: "item", label: "STATIC.NAV.SUPPORT", path: "/messages", icon: "contact_support", roles: ['super-admin','customer'] },
    { type: "separator" },
    {
      type: "group", label: "STATIC.NAV.SETTINGS", icon: "settings", roles: ['super-admin'],
      items: [
        { type: "item", label: "STATIC.NAV.USERS", path: "/customers", icon: "people", roles: ['super-admin'] },
        { type: "item", label: "STATIC.NAV.PARAMETERS", path: "/parameters", icon: "settings", roles:['super-admin'] },
        { type: "item", label: "STATIC.NAV.LISTS", path: "/lists", icon: "lists", roles:['super-admin'] },
        //{ type: "item", label: "STATIC.NAV.TASKS_PAGE", path: "/admin/tasks", icon: "build", roles:['super-admin'] },
       // { type: "item", label: "STATIC.NAV.HELPMESSAGES", path: "/help", icon: "help_outline", roles:['super-admin'] },
      ]
    },
    { type: "separator" },
  ];

  protected main_menu = [
    //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];


  protected profile_menu_auth = [
    { type: "item", label: "STATIC.NAV.PROFILE", icon: "account_circle", click: () => { this.showUserDialog() } },
    { type: "item", label: "STATIC.NAV.BILLING", icon: "receipt", click: () => { this.showBillings() } },
    { type: "item", label: "STATIC.NAV.ABOUT", icon: "info", click: () => { this.goToAboutPage() } },
    // { type: "item", label: "STATIC.NAV.APP_LINKEDIN_LOGIN", icon: "account_circle", click: () => { this.linkedinAppLogin() } },
    //{ type: "separator" },
  ];
  protected profile_menu = [
    //  { type: "item", label: "NAV.LOGOUT", path: "/logout", icon: "exit_to_app" },
  ];

  constructor(
    public auth: AuthenticationService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private dataService : DataService,
    private router: Router
  ) {
    const self = this;

  }

  getMenu(menu) {
    let user = this.auth.getCurrentAuthUser();

    switch (menu) {
      case 'main':
        return user && user.exists() ? this.main_menu_auth : this.main_menu;
        break
      case 'profile':
        return user && user.exists() ? this.profile_menu_auth : this.profile_menu;
        break

    }
  }

  logout() {
    this.auth.logout();
  }

  setTitle(title, subtitle = '') {
    if (title) {
      this.pageTitle = this.translate.instant(title);
      if (subtitle) {
        this.pageTitle += ' : ' + subtitle;
      }
    } else {
      this.pageTitle = '';
    }
  }

  getPageTitle() {
    return this.pageTitle;
  }

  showUserDialog() {
    let title = this.translate.instant('USER.MY_PROFILE');
    let user = this.auth.getCurrentUser();
    console.log("user = ", user)
    const dialogRef = this.dialog.open(EditUserDialog, {
      width: '570px',
      height: 'auto',
      autoFocus: false,
      disableClose: true,
      data: {
        title: title,
        item: user,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.auth.loadCurrentUser();
    });
  }

  goToAboutPage(){
    this.router.navigateByUrl('/page/about');
  }

  showBillings() {
    let user = this.auth.getCurrentUser();

    if (user.stripe_customer_id) {
      // calling the api to get the custormer portal url
      this.dataService.getAsPromise('auth/me/customerportal')
      .then(res=>{
        console.log('Stripe login endpoint',res)
        if (res.url) {
          location.href=res.url;
        }
      })

    } else {
      // not customer yet
      const dialogRef = this.dialog.open(AlertDialog, {
        // width: '370px',
        // height: '415px',
        autoFocus: false,
        disableClose: true,
        data: {
          title: this.translate.instant('BILLING.ALERT_TITLE'),
          text: this.translate.instant('BILLING.ALERT_MSG'),
        }
      });

    }
  }

 
}
