import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss']
})
export class DeleteAccountDialog {
  public form: FormGroup;
  public currentUser = null;
  public btn_save_loader: boolean = false;

  constructor(
    public auth: AuthenticationService,
    public dataService: DataService,
    private router: Router,
    public dialogRef: MatDialogRef<DeleteAccountDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.form = new FormGroup({
      deleteInfo: new FormControl(false, [Validators.requiredTrue])
    }, {
      updateOn: 'change',
    });
  }

  deleteAccount() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.btn_save_loader = true;
      this.currentUser = this.auth.getCurrentUser().id;
      this.dataService.putAsPromise(`admin/users/${this.currentUser}/disable`, {})
        .then(result => {
          if (result) { 
            this.dialogRef.close();
            this.auth.logout();
            this.router.navigate(['/']);
          }
        })
        .catch(error => {
          this.btn_save_loader = false;
          console.log('error', error);
        });
    }
  }


}
