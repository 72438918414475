import { Answer } from './answer';
import { Photo } from './photo';

export class Question {
  public id: number = null;
  public title: string = null;
  public type: string = null;
  public description: string = null;
  public order: number = null;
  public photo: Photo = null;
  public required: boolean = false;
  public properties: any = null;
  public config: [] = null;
  public answer: Answer = null;

  constructor(id?: number | Object) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id;
    }
  }

  exists() {
    return this.id != null;
  }

  import(data) {
    for (var key in data) {
      if (this.hasOwnProperty(key)) {
        switch (key) {
          case 'photo':
            this.photo = new Photo(data[key]);
            break;
            case 'answer':
              if (data[key]) {
              this.answer = new Answer(data[key]);
              }
              break;
          default:
            this[key] = data[key];
        }
      }
    }
  }
}
