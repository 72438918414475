import { HasRolesDirective } from './../../directives/hasroles.directive';
import { HasNotRolesDirective } from './../../directives/hasnotroles.directive';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { EventsDialog } from './../../components/dialogs/eventsdialog/events-dialog.component';
import { AlertComponent } from './../../components/misc/alert/alert.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SecurePipe } from './../../pipes/secure.pipe';
import { EditUserDialog } from '../../components/dialogs/userdialog/user-dialog.component';
import { AvatarComponent } from '../../components/misc/avatar/avatar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'ngx-avatar';
import { MomentModule } from 'ngx-moment';
import { ValidatorsModule } from 'ngx-validators';
import { ConfirmDialog } from 'src/app/components/dialogs/confirmdialog/confirm-dialog.component';
import { HasPermsDirective } from 'src/app/directives/hasperms.directive';
import { DropDownMenuComponent } from '../../components/menus/dropdownmenu/dropdownmenu.component';
import { HasNotPermsDirective } from './../../directives/hasnotperms.directive';
import { NgxUploaderModule } from 'ngx-uploader';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { QuillModule } from 'ngx-quill';
import { TableComponent } from './../../components/tables/table/table.component';

import { AutocompleteField } from 'src/app/components/forms/autocomplete-field/autocomplete-field.component';
import { EditableGridField } from 'src/app/components/forms/editablegridfield/editable-grid-field.component';
import { AjaxAutocompleteChipsInput } from 'src/app/components/forms/ajax-autocomplete-chips/ajax-autocomplete-chips.component';
import { AutocompleteCountryFieldComponent } from 'src/app/components/forms/autocomplete-country-field/autocomplete-country-field.component';
import { MenuItemComponent } from 'src/app/components/menus/dropdownmenu/menu-item/menu-item.component';
import { ExportDialog } from 'src/app/components/dialogs/exportdialog/export-dialog.component';
import { AlertDialog } from 'src/app/components/dialogs/alertdialog/alert-dialog.component';
import { MessageForm } from 'src/app/components/forms/message-form/message-form.component';
import { AnswersComponent } from 'src/app/components/answers/answers.component';
import { CompletedTaskDialogComponent } from '../admin/completed-tasks/completed-task-dialog/completed-task-dialog.component';
import { FileUploadInput } from 'src/app/components/forms/fileupload-field/fileupload-field.component';
import { DeleteAccountDialog } from 'src/app/components/dialogs/delete-account-dialog/delete-account-dialog.component';


@NgModule({
  declarations: [
    DropDownMenuComponent,
    ConfirmDialog,
    AlertDialog,
    EventsDialog,
    ExportDialog,
    EditUserDialog,
    DeleteAccountDialog,
    AnswersComponent,
    HasRolesDirective,
    HasNotRolesDirective,
    HasPermsDirective,
    HasNotPermsDirective,
    AvatarComponent,
    SecurePipe,
    SafePipe,
    AlertComponent,
    TableComponent,
    AutocompleteField,
    EditableGridField,
    AjaxAutocompleteChipsInput,
    FileUploadInput,
    MessageForm,
    AutocompleteCountryFieldComponent,
    MenuItemComponent,
    CompletedTaskDialogComponent,
  ],
  imports: [
    LayoutModule,
    CommonModule,
    ValidatorsModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatMenuModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatBadgeModule,
    FormsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    TranslateModule,
    MatDialogModule,
    MatAutocompleteModule,
    DragDropModule,
    MomentModule,
    AvatarModule,
    NgxUploaderModule,
    Nl2BrPipeModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatCheckboxModule,
    QuillModule,
    MatSelectCountryModule.forRoot('en'),
  ],
  exports: [
    CommonModule,
    LayoutModule,
    ValidatorsModule,
    MatButtonModule,
    MatBadgeModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatMenuModule,
    MatSelectModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    FormsModule,
    MatSliderModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatTabsModule,
    DropDownMenuComponent,
    ConfirmDialog,
    AlertDialog,
    EventsDialog,
    ExportDialog,
    EditUserDialog,
    AnswersComponent,
    MatDialogModule,
    TranslateModule,
    HasPermsDirective,
    HasNotPermsDirective,
    HasRolesDirective,
    HasNotRolesDirective,
    MatAutocompleteModule,
    DragDropModule,
    MomentModule,
    AvatarModule,
    NgxUploaderModule,
    AvatarComponent,
    SecurePipe,
    SafePipe,
    Nl2BrPipeModule,
    FlexLayoutModule,
    MatSnackBarModule,
    AlertComponent,
    TableComponent,
    AutocompleteField,
    EditableGridField,
    AjaxAutocompleteChipsInput,
    FileUploadInput,
    MessageForm,
    MatSelectCountryModule,
    AutocompleteCountryFieldComponent,
    MenuItemComponent,
    CompletedTaskDialogComponent

  ]
})
export class SharedModule {

}
