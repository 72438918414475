import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';


@Injectable()
export class MessagingService {
    messaging = null;
    _token = null;
    
    constructor(
      private data : DataService,
      private snack: MatSnackBar,
      private translate : TranslateService
    ) {
      try {
        this.messaging = firebase.messaging();
      } catch(err) {
        console.log('Messaging not supported by this browser', err)
      }
    }


    updateToken(userId, token) {
      if (!this.messaging) {
        return;
      }
       // Callback fired if Instance ID token is updated.
        this.messaging.onTokenRefresh(() => {
            this.messaging.getToken().then((refreshedToken) => {
              this.data.putAsPromise(`auth/me/fcm`, {token:refreshedToken})
              .then(res=>{
                console.log(res);
                this._token = refreshedToken;
              })
              .catch(err=>{
                console.log('error', err)
              })
            }).catch((err) => {
            console.log('Unable to retrieve refreshed token ', err);
         //   showToken('Unable to retrieve refreshed token ', err);
            });
        }); 
    }


    requestPermission() {
      try {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted' && !this._token) {
              console.log('Notification permission granted.');
              this.getToken();
              
            } else {
              console.log('Unable to get permission to notify.');
            }
          });
        }catch(err) {
          console.log('Notifications not supported by this browser', err)
        }
    }


    getToken() {
      if (!this.messaging) {
        return;
      }
        this.messaging.getToken().then((currentToken) => {
            if (currentToken) {
                this.data.putAsPromise(`auth/me/fcm`, {token:currentToken})
                  .then(res=>{
                    this._token = currentToken;
                  })
                  .catch(err=>{
                    console.log('error', err)
                  })
                  this.receiveMessage();
            } else {
              // Show permission request.
              console.log('No Instance ID token available. Request permission to generate one.');
              
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
      if (!this.messaging) {
        return;
      }
      this.messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        if (payload.notification && payload.notification.title) {
          this.snack.open(payload.notification.title, null, {
            duration: 3000,
          });
        }
        
      });
     
    }
}