// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration production` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api: {
    root: 'https://api.veriftoo.com/api',
  },
  fileserver: {
    downloads: 'https://api.veriftoo.com/api/downloads',
    images: 'https://api.veriftoo.com/api/images',
    public: 'https://api.veriftoo.com/',
  },
  auth: {
    providers: {
      google: { enabled: false }, // sign-in and register
      facebook: { enabled: false }, // sign-in and register
      email: {
        enabled: true,
        register: true,
        forgot: true,
        fields: {
          displayName: true,
          lastname: true,
          firstname: true,
          phone: true,
        },
      },
      phone: { enabled: false },
    },
    roles: {
      whitelist: null,
      blacklist: null,
    },
  },
  links: {
    cgu: 'https://wwww.veriftoo.com/cgu',
    privacyPolicy: 'https://www.veriftoo.com/privacy',
  },
  pusher: {
    key: '08c1f48467c5472a3c62',
    cluster: 'eu',
  },

  gmaps: {
    api: 'AIzaSyAYOxW8HmPPkN13a1V87quRBilttYtZFOM',
    default_location : { lat: 48.8588548, lng: 2.3466917 },
	geocodeURL: 'https://maps.googleapis.com/maps/api/geocode/json',
    maps_place: "https://www.google.com/maps/place/"
  },
  geolocation: {
    distance_filter: 1000,
  },

  // firebase
  firebase: {
    config: {
      apiKey: 'AIzaSyBBqeGFHc1Q2Y4ziS-Chu_fxdeO4EdF_KI',
      authDomain: 'veriftoo-9651c.firebaseapp.com',
      projectId: 'veriftoo-9651c',
      storageBucket: 'veriftoo-9651c.appspot.com',
      messagingSenderId: '563619870627',
      appId: '1:563619870627:web:6ef87343c49677bdca0c16',
      measurementId: 'G-J89TM3D8H3',
    },
    vapid_key:
      'BHO8p8O10-gBbFQuBBIcxFPBYwV9zfT6ihREBJUCq-k7gaCLTBLOde9TBt8XY6LXrM4VLuBj4BzXyQhwGdriSAc',
  },
  
  // add restriction for adding images for mission
  mission_restriction: {
    images: 5,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
