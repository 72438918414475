import { AuthenticationService } from '../services/authentication.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';

@Directive({ selector: '[hasNotPerms]' })
export class HasNotPermsDirective {
    private granted = false;

    @Input() set hasNotPerms(perms: Array<string>) {
        if (!perms || perms.length==0) {
            this.granted = true;
        } else {
            const authUser = this.auth.getCurrentAuthUser();
            if (authUser.exists()) {
                this.granted = _.intersection(authUser.permissions, perms).length==0;
            } else {
                this.granted = false;
            }

        }
        if (this.granted) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }

        
      }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private auth : AuthenticationService) { }



}