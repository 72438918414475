// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    root: 'http://localhost:8000/api',
  },
  fileserver: {
    downloads: 'http://localhost:8000/api/downloads',
    images: 'http://localhost:8000/api/images',
    public: 'http://localhost',
  },
  auth: {
    providers: {
      google: { enabled: false }, // sign-in and register
      facebook: { enabled: false }, // sign-in and register
      email: {
        enabled: true,
        register: false,
        forgot: false,
        fields: {
          displayName: true,
          lastname: true,
          firstname: true,
          phone: true,
        },
      },
      phone: { enabled: false },
    },
    roles: {
      whitelist: null,
      blacklist: null,
    },
  },
  links: {
    cgu: 'https://wwww.veriftoo.com/cgu',
    privacyPolicy: 'https://www.veriftoo.com/privacy',
  },
  pusher: {
    key: '08c1f48467c5472a3c62',
    cluster: 'eu',
  },

  gmaps : {
    api : 'AIzaSyAYOxW8HmPPkN13a1V87quRBilttYtZFOM',
    default_location : { lat: 45.6509677, lng: 25.5994993 },
    geocodeURL: 'https://maps.googleapis.com/maps/api/geocode/json',
    maps_place: "https://www.google.com/maps/place/"
  },
  
  // add restriction for adding images for mission
  mission_restriction: {
    images: 5,
  },

  // firebase
  firebase: {
    config: {
      apiKey: 'AIzaSyCnhVgkOGwC1z2qVt48A6GCDiNgrY79uQ8',
      authDomain: 'veriftoo-dev.firebaseapp.com',
      databaseURL:
        'https://veriftoo-dev-default-rtdb.europe-west1.firebasedatabase.app',
      projectId: 'veriftoo-dev',
      storageBucket: 'veriftoo-dev.appspot.com',
      messagingSenderId: '435958415085',
      appId: '1:435958415085:web:2e96f98124fc23e39056e4',
    },
    vapid_key:
      'BHO8p8O10-gBbFQuBBIcxFPBYwV9zfT6ihREBJUCq-k7gaCLTBLOde9TBt8XY6LXrM4VLuBj4BzXyQhwGdriSAc',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
