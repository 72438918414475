import { EventsDialog } from './../dialogs/eventsdialog/events-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PusherService } from './../../services/pusher.service';
import { AuthUser } from './../../models/AuthUser';
import { User } from './../../models/User';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { Component, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-logged-layout',
  templateUrl: './logged-layout.component.html',
  styleUrls: ['./logged-layout.component.scss']
})
export class LoggedLayoutComponent {
  user : User = null;
  authUser : AuthUser = null;
  sideMenu =[];
  profileMenu = [];
  ready = false;
  nb_notifications = 0;
  public isProd: boolean = false;
  public hideFilter: boolean = false;
  public menuTrigger = 'expand_more';

  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    public translate: TranslateService,
    public auth: AuthenticationService,
    public navService: NavigationService,
    public pusher : PusherService,
    public dialog: MatDialog

  ) {

    this.isProd =  environment.production;


    this.auth.currentAuthUser.subscribe(user=>{
      this.authUser = user;
      this.sideMenu = this.navService.getMenu('main');
      this.profileMenu = this.navService.getMenu('profile');
      this.ready = true;
    })
    this.auth.currentUser.subscribe(user=>{
      this.user = user;
    })

    this.pusher.eventsChanged.subscribe(n=>{
      this.nb_notifications = n;
    })

  }

  isItemVisible(item=null) {
    if (item && item.roles) {
      return this.authUser && this.authUser.hasRole(item.roles)
    }
    return true;
  }

  showEventsDialog() {
    const dialogRef = this.dialog.open(EventsDialog, {
      data: {
        title: this.translate.instant('NOTIFICATIONS.DIALOG_TITLE'),
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event) {
       this.nb_notifications = this.nb_notifications - 1;
      }

      if(result && result == 'bt_clear'){
        this.pusher.events = [];
        this.nb_notifications = 0;
      }
    });
  }

  minimizeFilter(){
    this.hideFilter = !this.hideFilter;
    if(this.hideFilter){
      this.panels.forEach(panel => {
        panel.close();
      });
    }
  }

  openFilter(){
    if(this.hideFilter){
      this.hideFilter = false;
    }
  }
  
  getLabelTooltip(label){
    return this.hideFilter ? this.translate.instant(label) : null;
  }
  
  menuOpened(){
    this.menuTrigger = 'expand_less';
  }

  menuClosed(){
    this.menuTrigger = 'expand_more';
  }
}
