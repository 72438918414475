import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const Geolocation = navigator.geolocation;

export interface Coords {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public place = null;
  public location: Coords = { lat: null, lng: null };
  public city = null;
  public country_iso = null;
  private watch_id = null;
  public last_watch: GeolocationPosition = null;
  public coords: Coords = null;
  private is_init: boolean = false;

  constructor() {}

  init() {}

  /*
   * Returns current position
   */
  getCurrentPosition(request_permissions = false): Promise<Coords> {
    return new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition(
        (coordinates) => {
          let coords: Coords;
          coords = {
            lat: coordinates.coords.latitude,
            lng: coordinates.coords.longitude,
          };
          resolve(coords);
        },
        (err) => {
          console.log('Error GPS', err);

          let coords: Coords;
          coords = environment.gmaps.default_location;
          resolve(coords);
          
          reject('gps_not_activated');
        },
        { timeout: 7500 }
      );
    });
  }

  watch() {
    this.watch_id = Geolocation.watchPosition(
      
      (position:GeolocationPosition) => {
        this.last_watch = position;
        
      },
      (err) => {
        console.log('Error GPS :', err);
        Geolocation.clearWatch(this.watch_id);
        setTimeout(() => {
          this.watch();
        }, 30000);
      
      },
      {
        enableHighAccuracy: true,
        maximumAge: 25000,
        timeout: 7500,
      },
    );
  }

  getDistance(answerLocation, taskLocation, unit = 'K') {
    if (answerLocation) {
      const pos = taskLocation;
      if (answerLocation.lat == pos.lat && answerLocation.lng == pos.lng) {
        return 0;
      } else {
        var radlat1 = (Math.PI * answerLocation.lat) / 180;
        var radlat2 = (Math.PI * pos.lat) / 180;
        var theta = answerLocation.lng - pos.lng;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == 'K') {
          dist = dist * 1.609344;
        }
        if (unit == 'N') {
          dist = dist * 0.8684;
        }
        return dist;
      }
    }
  }
}
