import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from '../components/dialogs/alertdialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class PusherService {

  private _echo = null;
  private token = null;
  public channelId = null;
  public events = [];

  private eventsChangedSubject: BehaviorSubject<number>;
  public eventsChanged: Observable<number>;
  private reloadMissionSubject: BehaviorSubject<number>;
  public reloadMission: Observable<number>;

  constructor(
    protected auth: AuthenticationService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router


  ) {
    this.eventsChangedSubject = new BehaviorSubject<number>(0);
    this.eventsChanged = this.eventsChangedSubject.asObservable();

    this.reloadMissionSubject = new BehaviorSubject<number>(0);
    this.reloadMission = this.reloadMissionSubject.asObservable();
    
    combineLatest( [this.auth.tokenRefreshed, this.auth.currentUser]).subscribe(
      ([token, user]) => {
        if (token && user) {
          this.token = token;
          this.channelId = user.notification_hash;
          if (token && this._echo) {
            this._echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + this.token;
          }
          this.subscribe();

        }
      }
    );

  }

  subscribe() {
    if (this.token && this.channelId && environment['pusher']) {
      this.events = [];
      setTimeout(() => { 
        this._echo = new Echo({
          broadcaster: 'pusher',
          auth: {
            headers: {
              Authorization: 'Bearer ' + this.token
            },
          },
          authEndpoint: environment.api.root + '/auth/broadcast',
          key: environment['pusher'].key,
          cluster: environment['pusher'].cluster
        });

        let privchannel = this._echo.private(`veriftoo-${this.channelId}`);

        privchannel.listen('PaymentSuccessEvent', (e) => {
          this.events.push(e);
          console.log("pusher",e)
          this.eventsChangedSubject.next(this.events.length);

          this.reloadMissionSubject.next(e.event.mission_id);

          const dialogRef = this.dialog.open(AlertDialog, {
            // width: '370px',
            // height: '415px',
            autoFocus: false,
            disableClose: true,
            data: {
              title: this.translate.instant('BILLING.ALERT_PAYMENT_SUCCESS_TITLE'),
              text: this.translate.instant('BILLING.ALERT_PAYMENT_SUCESS_MSG', {mission:e.event.title}),
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.router.navigateByUrl(`/missions/${e.event.mission_id}`);
          });

        });

        privchannel.listen('NotificationSupportEvent', (e) => {
          this.events.push(e);
          console.log("pusher",e)
          this.eventsChangedSubject.next(this.events.length);
        });

        privchannel.listen('ExportSavedEvent', (e) => {
          this.events.push(e);
          console.log("pusher",e)
          this.eventsChangedSubject.next(this.events.length);
        });
       
      }, 3000);
    }

  }

  removeEvent(event) {
    _.remove(this.events, (item)=>{
      return event.uri==item.event.uri;
    });
  }



}
