import { FileServer } from '../../../services/fileserver.service';
import { PasswordValidators } from 'ngx-validators';
import { User } from '../../../models/User';
import { DataService } from '../../../services/data.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'edit-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class EditUserDialog {
  public loader = false;
  public form: FormGroup;
  public mode = null;
  public item: User = null;
  public roles: any[] = ['super-admin'];
  protected server_errors = null;
  public imagePreview = null;
  // protected avatar = null;
  protected tabs_fields = {
    'account': ['email', 'password', 'password_confirmation', 'roles'],
    'infos': ['firstname', 'lastname', 'gender', 'mobile', 'country_iso']
  }
  protected save_clicked = false;
  public show_perms = false;
  public disabled_perms = false;
  public show_disabled = false;
  public editable = true;

  constructor(
    public dataService: DataService,
    private translate: TranslateService,
    public fs: FileServer,
    public dialogRef: MatDialogRef<EditUserDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {


    this.item = data.item;
    console.log(data);
    if (this.item.exists()) {
      this.item['password'] = '--NOCHANGE--';
      this.item['password_confirmation'] = '--NOCHANGE--';
    }
    let roles_validator = [];
    if (data.show_perms) {

      this.show_perms = true;
      roles_validator = [Validators.required];

      this.dataService.getAsPromise('admin/users/roles')
        .then(roles => {
          this.roles = roles;
        }).catch(err => {
          // console.log('error getting roles')
        })
    }

    if(data.show_disabled) {
      this.show_disabled = true;
    }


    this.form = new FormGroup({
      firstname: new FormControl('', [Validators.required, Validators.maxLength(128)]),
      lastname: new FormControl('', [Validators.required, Validators.maxLength(128)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(128)]),
      password: new FormControl('', [Validators.required, Validators.maxLength(128)]),
      password_confirmation: new FormControl('', [Validators.required, Validators.maxLength(128)]),
      gender: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [, Validators.maxLength(16)]),
      roles: new FormControl([], roles_validator),
      disabled: new FormControl('', []),
      country_iso: new FormControl('FR', [Validators.required]),
      vat_number: new FormControl(null),
      siren: new FormControl(null,[Validators.pattern("^[0-9]*$"),this.isSirenValid()]),
      zipcode: new FormControl(null),
      company: new FormControl(null),
      city: new FormControl(null),
      address1: new FormControl(null),
      address2: new FormControl(null),
      mode: new FormControl('admin'),
    }, [PasswordValidators.mismatchedPasswords('password', 'password_confirmation')]);


    this.form.patchValue(this.item);
    this.mode = this.item.exists() ? 'update' : 'new';

    if(this.item.exists()) {
      this.disabled_perms = true;
    } else {
      this.disabled_perms = false;
    }

    if(data.editable!=null) {
      this.editable = data.editable;
      if(this.editable == false){
        this.form.disable();
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    // console.log('SAVE', this.form.value, this.form)
    this.form.markAllAsTouched();
    this.save_clicked = true;
    this.resetServerErrors();

    if (this.form.valid) {
      this.loader = true;
      this.item.import(this.form.value);

      if (this.mode == 'update') {

        this.dataService.putAsPromise(`admin/users/${this.item.id}`, this.item)
          .then(res => {
            // if (this.avatar) {
            //   this.uploadAvatar()
            //     .then(res => {
            //       console.log("AFTER UPLOAD", res)
            //       this.dialogRef.close('bt_save');
            //     }).catch(error => {
            //       console.log('ERROR', error)
            //     })
            // } else {
            this.dialogRef.close('bt_save');
            this.loader = false;
          })
          .catch(error => {
            this.loader = false;
            console.log('ERROR', error)
          })

      } else {
        // console.log('POST');
        this.dataService.postAsPromise(`admin/users`, this.form.value)
          .then(res => {
            this.item.import(res.data.id);
            this.dialogRef.close('bt_save');
            this.loader = false;
          })
          .catch(error => {
            this.loader = false;
            console.log('ERROR', error);
            this.setServerErrors(error);
          })
      }

    } else {
      console.log('form not valid')
    }
  }

  // uploadAvatar() {
  //   return new Promise((resolve, reject) => {
  //     const formData = { 'avatar': this.imagePreview };
  //     this.dataService
  //       .putAsPromise(`admin/users/${this.item.id}/avatar`, formData)
  //       .then(res=>{
  //         resolve(res);
  //       })
  //       .catch(err=>{
  //         reject(err);
  //       })
  //   })
  // }

  tabError(tab) {
    if (!this.tabs_fields[tab] || !this.save_clicked) {
      return 0;
    }
    let n = 0;
    this.tabs_fields[tab].forEach(f => {
  
      if (!this.form.controls[f].valid) {
        n++;
      }
    });
    return n;
  }

  hasError(controlName: string, errorName: string) {
    if (!controlName) {
      return this.form.hasError(errorName);
    }
    return this.form.controls[controlName].hasError(errorName);
  }

  setServerErrors(error) {
    this.server_errors = error;
    for (let i in error.fields) {
      this.form.controls[i].setErrors({ serverError: true });
    }
  }
  resetServerErrors() {
    if (this.server_errors) {
      for (let i in this.server_errors.fields) {
       // this.form.controls[i].setErrors({ serverError: false });
        this.form.controls[i].markAsPristine();
        this.form.controls[i].markAsUntouched();
      }

      this.server_errors = null;
    }
  }

  getServerError(controlName: string) {
    if (this.server_errors && this.server_errors.fields && this.server_errors.fields[controlName]) {
      return this.translate.instant('STATIC.ERRORS.' + this.server_errors.fields[controlName].replace(/\./gi, ''));
    }
    return null;
  }

  isSirenValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value as string;
      let total = 0;
      let bal = 0;

      if (!value) {
        return null;
      }

      if (value.length < 9 || value.length > 9) {
        return { lengthInvalid: true };
      }

      for (let i = value.length - 1; i >= 0; i--) {
        var step = Number.parseInt(value[i]) * (bal + 1);
        total += step > 9 ? step - 9 : step;
        bal = 1 - bal;
      }

      if (total % 10 != 0) {
        return { sirenInvalid: true };
      }
      return null;
    };
  }

  // onImageChange(event) {
  //   console.log('onImageChange', event)
  //   this.avatar = event.target.files[0];
  //   this.imagePreview = null;
  //   this.getImagePreview(this.avatar);
  // }

  // getImagePreview(file: File) {
  //   const self = this;
  //   const reader: FileReader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {

  //     this.imagePreview = reader.result;

  //   };

  // }

}
