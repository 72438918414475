export enum Status{
    New = "new",
    Reserved = "reserved",
    Released = "released",
    Validated = 'validated',
    In_progress = "in_progress",
    Completed = "completed",
    Accepted = "accepted",
    Rejected = "rejected",
    Pending = 'pending',
    Canceled = 'canceled',
    Finalized = 'finalized',
    Expired = 'expired'
}
